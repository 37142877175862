import { useState, useEffect, useRef } from "react"
import type { ComponentType } from "react"

// UI Constants
const FONT_FAMILY = "Inter, sans-serif"
const BORDER_RADIUS = "14px"
const BORDER_WIDTH = "0px"
const ICON_SIZE = "16px"

// Spacing Constants
const PADDING_VERTICAL = "14px"
const PADDING_HORIZONTAL = "16px"
const FILTER_GAP = "4px"
const FILTER_BOTTOM_MARGIN = "0px"

// Color Constants
const TEXT_COLOR = "#1E293B"
const BORDER_COLOR = "#FFF"
const BACKGROUND_COLOR = "#FFF"
const SELECTED_BACKGROUND_COLOR = "#FFF"
const ACTIVE_BORDER_COLOR = "#E7E7E7"
const HOVER_BACKGROUND_COLOR = "#FCFCFC"
const ACTIVE_TEXT_COLOR = "#1E293B"

// Typography Constants
const TEXT_SIZE = "15px"
const SEARCH_TEXT_SIZE = "16px"
const TEXT_WEIGHT = "500"

// Effects Constants
const BOX_SHADOW =
    "0px 0.301px 1.505px -1.5px rgba(0, 0, 0, 0.18), 0px 1.144px 5.721px -3px rgba(0, 0, 0, 0.04)"

// Toggle search functionality
const ENABLE_SEARCH = true

// Mobile Breakpoint
const MOBILE_BREAKPOINT = "810px"

// Base styles for SELECTS
const baseSelectStyles = {
    transition: "all 0.2s ease-in-out",
    letterSpacing: "-0.04em",
    borderRadius: BORDER_RADIUS,
    border: `${BORDER_WIDTH} solid ${BORDER_COLOR}`,
    background: BACKGROUND_COLOR,
    boxShadow: BOX_SHADOW,
    padding: `${PADDING_VERTICAL} ${PADDING_HORIZONTAL}`,
    fontFamily: FONT_FAMILY,
    color: TEXT_COLOR,
    fontSize: TEXT_SIZE,
    fontWeight: TEXT_WEIGHT,
    boxSizing: "border-box" as const,
    width: "auto",
    appearance: "none",
    whiteSpace: "nowrap" as const,
    overflow: "visible" as const,
    textOverflow: "clip" as const,
    paddingRight: `calc(${PADDING_HORIZONTAL} + ${ICON_SIZE} + 2px)`,
    position: "relative" as const,
    cursor: "pointer",
}

// Base styles for the SEARCH input
const baseSearchStyles = {
    ...baseSelectStyles,
    width: "100%",
    minWidth: "100%",
    fontSize: SEARCH_TEXT_SIZE, // Use larger font size for search input
}

export function SortCMS(Component: ComponentType): ComponentType {
    return (props: any) => {
        // 1) Skip SSR to avoid hydration mismatch.
        if (typeof window === "undefined") {
            return <Component {...props} />
        }

        // ----- Client side only below -----
        const [filters, setFilters] = useState<{ [key: string]: string }>({})
        const [sortOption, setSortOption] = useState<string>("relevant")
        const [searchQuery, setSearchQuery] = useState("")
        const [originalOrder, setOriginalOrder] = useState<Element[]>([])
        const [sortOptions, setSortOptions] = useState<string[]>([])
        const [hasSortElements, setHasSortElements] = useState(false)
        const [isMobile, setIsMobile] = useState(false)
        const selectRefs = useRef<{ [key: string]: HTMLSelectElement | null }>(
            {}
        )
        const searchRef = useRef<HTMLInputElement | null>(null)
        const debounceTimer = useRef<number | null>(null)

        // Check if the viewport is mobile width
        const checkMobileView = () => {
            setIsMobile(window.innerWidth <= 810)
        }

        // Utility functions
        const extractNumber = (str: string): number => {
            const matches = str.match(/-?[\d,]+\.?\d*/g)
            if (!matches) return 0
            const numStr = matches[0].replace(/,/g, "")
            return parseFloat(numStr) || 0
        }

        const parseDate = (dateString: string): number => {
            if (!dateString) return 0
            try {
                // Fix: Handle more date formats and add error checking
                const parts = dateString.split(/[\/\-.]/)
                if (parts.length !== 3) return 0

                const [day, month, year] = parts.map(Number)
                if (isNaN(day) || isNaN(month) || isNaN(year)) return 0

                // Assume 20xx for two-digit years
                const fullYear = year < 100 ? year + 2000 : year
                return new Date(fullYear, month - 1, day).getTime()
            } catch {
                return 0
            }
        }

        const parsePrice = (priceString: string): number => {
            if (!priceString) return 0
            const numericString = priceString.replace(/[^\d.]/g, "")
            return parseFloat(numericString) || 0
        }

        // Adjust each select to only the currently selected option's width
        const adjustSelectWidth = (id: string) => {
            const select = selectRefs.current[id]
            if (!select) return

            // On mobile, don't adjust width - let them be full width
            if (isMobile) {
                select.style.width = "100%"
                return
            }

            const tempSpan = document.createElement("span")
            tempSpan.style.visibility = "hidden"
            tempSpan.style.position = "absolute"
            tempSpan.style.whiteSpace = "nowrap"
            tempSpan.style.font = window.getComputedStyle(select).font
            document.body.appendChild(tempSpan)

            // Only measure the selected option
            tempSpan.textContent =
                select.options[select.selectedIndex]?.text || ""
            const textWidth = tempSpan.offsetWidth

            document.body.removeChild(tempSpan)

            // Fix: Use safe parsing for integers and add fallbacks
            const paddingHorizontal = parseInt(PADDING_HORIZONTAL) || 16
            const iconSize = parseInt(ICON_SIZE) || 16
            const extraSpace = paddingHorizontal * 2 + iconSize + 8
            select.style.width = `${textWidth + extraSpace}px`
        }

        // Handlers
        const handleFilterChange = (filterId: string, value: string) => {
            setFilters((prev) => ({ ...prev, [filterId]: value }))
            setTimeout(() => adjustSelectWidth(filterId), 0)
        }

        const handleSortChange = (value: string) => {
            setSortOption(value)
            setTimeout(() => adjustSelectWidth("sort"), 0)
        }

        const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchQuery(e.target.value)
        }

        // Apply filters, search, and sort with debouncing
        const updateDisplay = () => {
            const layer = document.querySelector(`.${props.className}`)
            if (!layer) return

            const cards = Array.from(
                layer.querySelectorAll("[aria-label='cmsitem']")
            )

            // Store original order once
            if (originalOrder.length === 0 && cards.length > 0) {
                setOriginalOrder([...cards])
            }

            // FILTER + SEARCH
            cards.forEach((card) => {
                let matchAll = true

                // Apply filter selections
                Object.entries(filters).forEach(([filterId, filterValue]) => {
                    if (filterValue) {
                        // Find all elements with this filter in the card
                        const filterElements = card.querySelectorAll(
                            `[aria-label^="filter-${filterId}"], [aria-label^="sort-filter-${filterId}"], [aria-label^="filter-sort-${filterId}"]`
                        )

                        // If no elements found, this doesn't match the filter
                        if (filterElements.length === 0) {
                            matchAll = false
                            return
                        }

                        // Check if at least one element matches the selected value
                        let hasMatch = false
                        filterElements.forEach((element) => {
                            const text =
                                element.textContent?.trim().toLowerCase() || ""
                            if (text === filterValue.toLowerCase()) {
                                hasMatch = true
                            }
                        })

                        if (!hasMatch) {
                            matchAll = false
                        }
                    }
                })

                // Apply search
                if (ENABLE_SEARCH && searchQuery) {
                    const cardText = card.textContent?.toLowerCase() || ""
                    if (!cardText.includes(searchQuery.toLowerCase())) {
                        matchAll = false
                    }
                }

                card.classList.toggle("hidden", !matchAll)
            })

            // SORT - only if we have visible cards and it's not set to "relevant"
            const visibleCards = cards.filter(
                (card) => !card.classList.contains("hidden")
            )

            if (visibleCards.length > 0) {
                if (sortOption !== "relevant") {
                    // Fix: Better handling for sort option parsing
                    const parts = sortOption.split("-")
                    if (parts.length < 2) return

                    const field = parts[0]
                    const direction = parts[parts.length - 1]

                    visibleCards.sort((a, b) => {
                        const aEl = a.querySelector(
                            `[aria-label^="sort-${field}"], [aria-label^="sort-filter-${field}"], [aria-label^="filter-sort-${field}"]`
                        )
                        const bEl = b.querySelector(
                            `[aria-label^="sort-${field}"], [aria-label^="sort-filter-${field}"], [aria-label^="filter-sort-${field}"]`
                        )
                        const aValue = aEl?.textContent?.trim() || ""
                        const bValue = bEl?.textContent?.trim() || ""

                        if (field === "date") {
                            const dateA = parseDate(aValue)
                            const dateB = parseDate(bValue)
                            return direction === "asc"
                                ? dateA - dateB
                                : dateB - dateA
                        } else if (field === "price") {
                            const priceA = parsePrice(aValue)
                            const priceB = parsePrice(bValue)
                            return direction === "asc"
                                ? priceA - priceB
                                : priceB - priceA
                        } else {
                            const hasNumberA = /-?[\d,]+\.?\d*/g.test(aValue)
                            const hasNumberB = /-?[\d,]+\.?\d*/g.test(bValue)
                            if (hasNumberA && hasNumberB) {
                                const numA = extractNumber(aValue)
                                const numB = extractNumber(bValue)
                                return direction === "asc"
                                    ? numA - numB
                                    : numB - numA
                            }
                            return direction === "asc"
                                ? aValue.localeCompare(bValue)
                                : bValue.localeCompare(aValue)
                        }
                    })
                } else if (originalOrder.length > 0) {
                    // Restore original ordering for visible cards
                    visibleCards.sort((a, b) => {
                        return (
                            originalOrder.indexOf(a) - originalOrder.indexOf(b)
                        )
                    })
                }

                // Re-append them in the new order
                visibleCards.forEach((card) => {
                    layer.appendChild(card)
                })
            }
        }

        // Effect for detecting filters and sort options
        useEffect(() => {
            const layer = document.querySelector(`.${props.className}`)
            if (!layer) return

            const filterElements = layer.querySelectorAll(
                '[aria-label^="filter-"], [aria-label^="sort-filter-"], [aria-label^="filter-sort-"]'
            )
            const uniqueFilters: { [key: string]: Set<string> } = {}
            const discoveredSortOptions = new Set<string>()

            filterElements.forEach((el) => {
                const label = el.getAttribute("aria-label") || ""
                const parts = label.split("-")
                // Fix: More robust way to extract filter ID
                if (parts.length < 2) return
                const id = parts[parts.length - 1]
                if (id) {
                    if (!uniqueFilters[id]) {
                        uniqueFilters[id] = new Set()
                    }
                    const content = el.textContent?.trim() || ""
                    if (content) {
                        uniqueFilters[id].add(content)
                    }
                }
            })

            // Check if any sort elements exist
            const sortElements = layer.querySelectorAll(
                '[aria-label^="sort-"], [aria-label^="sort-filter-"], [aria-label^="filter-sort-"]'
            )
            setHasSortElements(sortElements.length > 0)

            if (sortElements.length > 0) {
                sortElements.forEach((el) => {
                    const label = el.getAttribute("aria-label") || ""
                    const parts = label.split("-")
                    // Fix: More robust extraction of sort field
                    if (parts.length < 2) return
                    const id = parts[parts.length - 1]
                    if (id && id !== "filter") {
                        discoveredSortOptions.add(id)
                    }
                })
            }

            // Initialize empty filters
            const initialFilters: { [key: string]: string } = {}
            Object.keys(uniqueFilters).forEach((filterId) => {
                initialFilters[filterId] = ""
            })
            setFilters(initialFilters)

            // Set sort options if we have any
            if (discoveredSortOptions.size > 0) {
                setSortOptions([
                    "relevant",
                    ...Array.from(discoveredSortOptions),
                ])
            } else {
                setSortOptions([])
            }

            // Clear any debounce timer on cleanup
            return () => {
                if (debounceTimer.current) {
                    clearTimeout(debounceTimer.current)
                }
            }
        }, [props.className])

        // Effect for applying filters and sorting
        useEffect(() => {
            // Debounce for better performance
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current)
            }

            // Fix: Safe type casting for setTimeout
            debounceTimer.current = window.setTimeout(() => {
                updateDisplay()
            }, 50) as unknown as number

            return () => {
                if (debounceTimer.current) {
                    clearTimeout(debounceTimer.current)
                }
            }
        }, [props.className, filters, sortOption, searchQuery, originalOrder])

        // Effect for adjusting select widths
        useEffect(() => {
            Object.keys(filters).forEach(adjustSelectWidth)
            if (hasSortElements && sortOptions.length > 0) {
                adjustSelectWidth("sort")
            }
        }, [filters, sortOption, hasSortElements, sortOptions, isMobile])

        // Effect for mobile view detection
        useEffect(() => {
            // Initial check
            checkMobileView()

            // Add resize listener
            window.addEventListener("resize", checkMobileView)

            // Cleanup
            return () => {
                window.removeEventListener("resize", checkMobileView)
            }
        }, [])

        // Get unique filter options for a given filter ID
        const getFilterOptions = (filterId: string) => {
            const layer = document.querySelector(`.${props.className}`)
            if (!layer) return []

            const options = new Set<string>()

            // Collect all unique values for this filter
            const elements = layer.querySelectorAll(
                `[aria-label^="filter-${filterId}"], [aria-label^="sort-filter-${filterId}"], [aria-label^="filter-sort-${filterId}"]`
            )

            elements.forEach((el) => {
                const value = el.textContent?.trim()
                if (value) {
                    options.add(value)
                }
            })

            return Array.from(options).sort()
        }

        return (
            <>
                {(Object.keys(filters).length > 0 ||
                    (ENABLE_SEARCH && !searchQuery)) && (
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: FILTER_GAP,
                            marginBottom: FILTER_BOTTOM_MARGIN,
                            justifyContent: "flex-start",
                            width: "100%",
                        }}
                    >
                        {ENABLE_SEARCH && (
                            <div
                                style={{
                                    position: "relative",
                                    display: "inline-block",
                                    width: isMobile ? "100%" : "auto",
                                    maxWidth: isMobile ? "100%" : "300px",
                                    marginBottom: "0px",
                                }}
                            >
                                <input
                                    ref={searchRef}
                                    type="text"
                                    placeholder="Search..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    style={baseSearchStyles}
                                />
                                <span
                                    style={{
                                        position: "absolute",
                                        right: PADDING_HORIZONTAL,
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        pointerEvents: "none",
                                        display: "flex",
                                        alignItems: "center",
                                        color: TEXT_COLOR,
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        style={{
                                            width: ICON_SIZE,
                                            height: ICON_SIZE,
                                        }}
                                    >
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                        ></line>
                                    </svg>
                                </span>
                            </div>
                        )}

                        {Object.entries(filters).map(
                            ([filterId, filterValue]) => {
                                const isActive = !!filterValue
                                const style = {
                                    ...baseSelectStyles,
                                    background: isActive
                                        ? SELECTED_BACKGROUND_COLOR
                                        : BACKGROUND_COLOR,
                                    borderColor: isActive
                                        ? ACTIVE_BORDER_COLOR
                                        : BORDER_COLOR,
                                    color: isActive
                                        ? ACTIVE_TEXT_COLOR
                                        : TEXT_COLOR,
                                    width: isMobile ? "100%" : "auto",
                                    marginBottom: isMobile ? FILTER_GAP : "0",
                                }
                                return (
                                    <div
                                        key={filterId}
                                        style={{
                                            position: "relative",
                                            display: "inline-block",
                                            width: isMobile ? "100%" : "auto",
                                        }}
                                    >
                                        <select
                                            ref={(el) =>
                                                (selectRefs.current[filterId] =
                                                    el)
                                            }
                                            value={filterValue}
                                            onChange={(e) =>
                                                handleFilterChange(
                                                    filterId,
                                                    e.target.value
                                                )
                                            }
                                            style={style}
                                        >
                                            <option value="">
                                                {filterId === "category"
                                                    ? "All categories"
                                                    : `All ${filterId}`}
                                            </option>
                                            {getFilterOptions(filterId).map(
                                                (option) => (
                                                    <option
                                                        key={option}
                                                        value={option}
                                                    >
                                                        {option}
                                                    </option>
                                                )
                                            )}
                                        </select>

                                        <span
                                            style={{
                                                position: "absolute",
                                                right: PADDING_HORIZONTAL,
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                pointerEvents: "none",
                                                display: "flex",
                                                alignItems: "center",
                                                color: isActive
                                                    ? ACTIVE_TEXT_COLOR
                                                    : TEXT_COLOR,
                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                style={{
                                                    width: ICON_SIZE,
                                                    height: ICON_SIZE,
                                                }}
                                            >
                                                <polyline points="6 9 12 15 18 9" />
                                            </svg>
                                        </span>
                                    </div>
                                )
                            }
                        )}

                        {/* Only show sort dropdown if sort elements exist */}
                        {hasSortElements && sortOptions.length > 1 && (
                            <div
                                style={{
                                    position: "relative",
                                    display: "inline-block",
                                    width: isMobile ? "100%" : "auto",
                                }}
                            >
                                <select
                                    ref={(el) =>
                                        (selectRefs.current["sort"] = el)
                                    }
                                    value={sortOption}
                                    onChange={(e) =>
                                        handleSortChange(e.target.value)
                                    }
                                    style={{
                                        ...baseSelectStyles,
                                        background:
                                            sortOption !== "relevant"
                                                ? SELECTED_BACKGROUND_COLOR
                                                : BACKGROUND_COLOR,
                                        borderColor:
                                            sortOption !== "relevant"
                                                ? ACTIVE_BORDER_COLOR
                                                : BORDER_COLOR,
                                        color:
                                            sortOption !== "relevant"
                                                ? ACTIVE_TEXT_COLOR
                                                : TEXT_COLOR,
                                        width: isMobile ? "100%" : "auto",
                                        marginBottom: isMobile
                                            ? FILTER_GAP
                                            : "0",
                                    }}
                                >
                                    <option value="relevant">
                                        Sort by: Relevant
                                    </option>
                                    {sortOptions
                                        .filter(
                                            (option) => option !== "relevant"
                                        )
                                        .map((option) => (
                                            <>
                                                <option
                                                    key={`${option}-asc`}
                                                    value={`${option}-asc`}
                                                >
                                                    {option
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        option.slice(1)}{" "}
                                                    Ascending
                                                </option>
                                                <option
                                                    key={`${option}-desc`}
                                                    value={`${option}-desc`}
                                                >
                                                    {option
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        option.slice(1)}{" "}
                                                    Descending
                                                </option>
                                            </>
                                        ))}
                                </select>

                                <span
                                    style={{
                                        position: "absolute",
                                        right: PADDING_HORIZONTAL,
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        pointerEvents: "none",
                                        display: "flex",
                                        alignItems: "center",
                                        color:
                                            sortOption !== "relevant"
                                                ? ACTIVE_TEXT_COLOR
                                                : TEXT_COLOR,
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        style={{
                                            width: ICON_SIZE,
                                            height: ICON_SIZE,
                                        }}
                                    >
                                        <polyline points="6 9 12 15 18 9" />
                                    </svg>
                                </span>
                            </div>
                        )}
                    </div>
                )}

                <Component {...props} />

                <style>{`
                    .${props.className} .hidden {
                        display: none;
                    }
                    select:hover, input:hover {
                        background-color: ${HOVER_BACKGROUND_COLOR} !important;
                    }
                    select {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                    }
                    select:focus, input:focus {
                        outline: none;
                        border-color: ${ACTIVE_BORDER_COLOR};
                        background-color: ${SELECTED_BACKGROUND_COLOR};
                    }
                    select:active, input:active {
                        outline: none;
                        border-color: ${ACTIVE_BORDER_COLOR};
                        background-color: ${SELECTED_BACKGROUND_COLOR};
                    }
                    
                    @media (max-width: ${MOBILE_BREAKPOINT}) {
                        select, input[type="text"] {
                            width: 100% !important;
                            margin-bottom: 4px !important;
                        }
                    }
                `}</style>
            </>
        )
    }
}
